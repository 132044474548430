$white: #fff;
$black: #000000;
$opaqueBlack: #00000080;
$background: #24292E;
$purple: #C4FF03;
$gray: #00000060;
$pink: #DAD3F8;
$purpleOpacity: hsla(313, 100%, 45%, .1);
$blue: #C4FF03;
$success: #C4FF03;
$green: #C4FF03;
$green-gradient: linear-gradient(#4c98de 0%, #73f653 100%);
$blueOpaque: #4a36a284;
$opacityblack: rgba(0, 0, 0, 0.5);
$transparentBlack: hsla(0, 0%, 0%, 40%);
$yellow: #FFE347;
$whiteDark: #F0EEFE;
$blueMd: rgba(255, 255, 255, 0.5);
$blueBlur: rgba(255, 255, 255, 0.15);
$fontColorBlack: #333;
$red: #EB5D5D;
$lGray: #868686;

$blue-v2:#C4FF03;
// **************************************************************************************************
// skinz color
$s_blue: #C4FF03;

$sf-pro-light: sfProDisplayLight;
$sf-pro-regular: sfProDisplayRegular;
$sf-pro-medium: sfProDisplayMedium;
$sf-pro-semi-bold: sfProDisplaySemiBold;
$sf-pro-bold: sfProDisplayBold;
$sf-pro-heavy: sfProDisplayHeavy;
$poppinsblack: poppinsblack;
$poppinsBold: poppinsBold;
$poppinsEXTRABOLD: poppinsEXTRABOLD;
$poppinsEXTRALIGHT: poppinsEXTRALIGHT;
$poppinsLight: poppinsLight;
$poppinsMEDIUM: poppinsMEDIUM;
$poppinsSEMIBOLD: poppinsSEMIBOLD;
$poppinsTHIN: poppinsTHIN;
$bogam: Bogam;
$urbanist: "Urbanist", sans-serif;

$sm: 480px;
$sms: 425px;
$small-device: 500px;
$xxs: 320px;
$xls: 600px;
$xlsh: 500px;
$xms: 375px;
$md: 890px;
$mdh: 720px;
$lg: 1025px;
$xlg: 1600px;
$xxlg: 5000px;
$headervh: 100vh;
$header-padding: 10vw;
$divider: (
    4/3
);
$xd-width: 1920;
$xd-height: 1080;
$width-of-container: (
    85px/$divider
);
$menu-height: 54px;
$gray_color: #EFE9F8;
$gray-main-color: #bfbfbf;
$divider: 2;