@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#CartPopOver {
    background-color: #1B1F23;
    width: 400px;
    padding-top: 12px;

    :global {
        .header {
            padding-left: 24px;
            padding-right: 24px;
            margin-bottom: 40px;

            .h-flex {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                svg {
                    height: 40px;
                    width: 40px;
                }
            }
        }

        .cart-text {

            font-family: $sf-pro-semi-bold;
            font-style: normal;
            font-weight: 600;
            font-size: pxToEm(24);
            line-height: 1;
            text-align: center;
            color: $white;
        }

        .items {
            background-color: #24292E;
            font-family: $sf-pro-regular;
            font-style: normal;
            font-weight: 400;
            font-size: pxToEm(14);
            line-height: 1;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $white;
            height: 35px;
            display: grid;
            place-items: center;
            margin-bottom: 16px;
        }
    }
}