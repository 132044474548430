@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.singleGame {
    background: #2D3339;
    padding: 20px;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :global {
        .top {
            display: flex;
            justify-content: space-between;
        }

        .devenv {
            padding: 8px 16px;
            background: $blue-v2;
            color: $white;
            font-family: $sf-pro-medium;
            font-size: 1em;
            line-height: 1;
        }

        .game_name {
            font-family: $sf-pro-heavy;
            font-size: 2.5em;
            color: $white;
            line-height: 1;
            letter-spacing: 4px;
            text-transform: uppercase;
        }

        .bottom {
            display: flex;
            justify-content: space-between;
        }

        .add_game_glbs {
            padding: 12px;
            background: darken($color: complement($color: $blue), $amount: 30);
            display: flex;
            gap: 20px;
            font-family: $sf-pro-medium;
            font-size: 1.3em;
            line-height: 1;
            color: $white;
            width: 50%;
        }
    }
}