@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

#Feature {

    :global {
        display: flex;
        padding-top: pxToEm(47);

        .features {
            width: pxToEm(566);

            @include mq($xlg) {
                width: pxToEm(566);
            }
        }

        .single-feature {
            font-family: $urbanist;
            font-weight: 100;
            font-size: pxToEm(48);
            text-align: left;
            color: $lGray;
            margin-bottom: 19px;
            line-height: 58/48;

            @include mq($xlg) {
                font-size: 30px;
            }
        }

        .active {
            border-bottom: 1px solid $lGray;
            color: $green;
        }

        .feature-desc {
            font-family: $urbanist;
            font-weight: 300;
            font-size: pxToEm(30);
            text-align: left;
            padding-top: pxToEm(79);
            color: $lGray;

            @include mq($xlg) {
                font-size: 16px;
            }
        }

        img {
            width: 100%;
            height: auto;

            @include mq($xlg) {
                padding-right: 30px;
            }
        }
    }
}

//     @include landingPadding;
// }