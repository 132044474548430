@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
#mainNavBar {
  :global {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    input{
      color: white;
    }
    @include mq($xlg) {
      gap: 35px;
    }
    @include mq($lg) {
      gap: 30px;
    }
    @include mq($md) {
      gap: 20px;
    }
    .imgeLogo {
      height: 60px;
      @include mq($lg) {
        height: 50px;
      }
      @include mq($sm) {
        height: 30px;
      }
    }
    .navlinks {
      align-self: left;
      margin-left: 2.71978021978022%;
      margin-right: auto;
      display: flex;
      gap: 24px;
      mix-blend-mode: normal;
      @include mq($lg) {
        gap: 18px;
      }
      @include mq($md) {
        display: none;
      }
      @include mq($sm) {
        gap: 15px;
      }
      .link {
        padding-bottom: 6px;
        cursor: pointer;
        list-style-type: none;
        list-style: none;
        @include mq($lg) {
          @include mqh($sm) {
            padding-bottom: 4px;
          }
        }
        .linkTag {
          color: $white;
          font-family: $sf-pro-medium;
          font-weight: normal;
          font-size: 16px;
          text-align: left;
          text-decoration: none;
          @include mq($md) {
            font-size: 14px;
          }
          @include mq($xms) {
            font-size: 12px;
          }
        }
      }
      .linkActive {
        border-bottom: 3px solid $purple;
      }
    }
    .rightNavoptions {
      display: flex;
      gap: 9.22212495673948%;
      width: 26.98956043956044%;
      padding: 0;
      align-items: center;
      @include mq($xlg) {
        gap: 7.22212495673948%;
        width: 30.98956043956044%;
      }
      @include mq($lg) {
        width: 32.98956043956044%;
      }
      @include mq($md) {
        width: 50%;
      }
      .searchForm {
        flex-grow: 1;
        max-width: 82.4392825878952%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        border-radius: 0px;
        background: transparent;
        backdrop-filter: blur(30px);
        --webkit-backdrop-filter: blur(30px);
        background-color: $blueBlur;
        @include mq($lg) {
          padding: 0 20px;
          height: 50px;
        }
        @include mq($md) {
          padding: 0 20px;
          height: 50px;
        }
        .searchInput {
          min-height: 90%;
          max-width: 80%;
          min-width: 80%;
          border-radius: 0px;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background: transparent;
          border: none;
          outline: none;
          box-shadow: none;
          &::placeholder {
            color: $white;
            font-family: $sf-pro-regular;
            font-size: 16px;
            @include mq($md) {
              font-size: 14px;
            }
            @include mq($xms) {
              font-size: 12px;
            }
          }
          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }
        }
        .searchIcon {
          cursor: pointer;
          @include mq($sm) {
            font-size: 14px;
          }
        }
      }
      .menu {
        @include mq($lg) {
          height: 100%;
        }
      }
    }
  }
}
