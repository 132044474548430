@import "../../../../../../sass/mixins.scss";
@import "../../../../../../sass/variables.scss";

.updateTexturePopUp {
  :global {
    border-radius: 15px;
    background: transparent;
    backdrop-filter: blur(34px);
    --webkit-backdrop-filter: blur(34px);
    background-color: none;
    border-radius: 0;
    height: 100%;
    width: 90%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 11px;
    .header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .box {
        height: 24px;
        aspect-ratio: 1/1;
        width: auto;
        cursor: pointer;
        background-color: darken($color: $white, $amount: 12);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        .closeIcon {
          width: 33.33333333333333%;
          height: 33.33333333333333%;
        }
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      color: $background;
      font-family: $sf-pro-medium;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 1;
      border-radius: inherit;
      background: transparent;
      backdrop-filter: blur(10px);
      --webkit-backdrop-filter: blur(10px);
      background-color: rgba($color: $white, $alpha: 0.2);
      padding: 16px;
      font-size: 14px;
      outline: none;
      color:$white;
      @include scroll-bar;
      overflow-y: hidden;
      border: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      @include mq($xlg) {
        font-size: 12px;
      }
      @include mq($lg) {
        padding: 14px;
        font-size: 10px;
      }
    }
    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
