@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#editTexturePopUp {
  :global {
    input[type="number"] {
      background: none;
      outline: none;
      border: none;
      font-family: $sf-pro-medium;
      font-size: 1em;
      display: grid;
      place-items: center;
      text-align: center;
    }

    position: fixed;
    z-index: 100;
    display: grid;
    place-items: center;
    background-color: #ffffff42;
    backdrop-filter: blur(10px);
    min-width: 100%;
    min-height: calc(100vh - pxToVh(136));
    top: 0;
    right: 0;
    border-radius: 0px;
    --webkit-backdrop-filter: blur(10px);
    // background-color: rgba(255, 255, 255, 0.318);

    .child {
      background-color: $white;
      min-height: auto;
      max-width: 70%;
      padding: 20px;
      border-radius: 0px;
      max-height: auto;

      @include mq($lg) {}

      // @include mqh($xlg) {
      //   min-height: 50%;
      //   max-height: 70%;
      // }

      // @include mq($md) {
      //   min-height: 70%;
      //   max-width: 100%;
      //   padding: 15px;
      // }

      // @include mqh($sm) {
      //   min-height: 100%;
      //   max-height: 100%;
      // }

      .PopUpHeader {
        display: flex;
        justify-content: space-between;

        .title {
          font-family: $sf-pro-medium;
          font-weight: 900;
          font-size: 20px;
          color: $black;
          margin-bottom: 20px;

          @include mq($lg) {
            font-size: 18px;
          }

          @include mq($md) {
            font-size: 16px;
          }

          @include mq($sm) {
            font-size: 14px;
          }

          @include mqh($md) {
            margin-bottom: 10px;
          }
        }

        .subtitle {
          font-family: $sf-pro-regular;
          font-weight: 500;
          font-size: 16px;
          text-align: left;
          color: $black;
          opacity: 0.49;
        }
      }

      .closeBtn {
        max-height: 35px;
        cursor: pointer;
      }

      .bgEdit {
        margin-top: 10px;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 1;
        align-items: center;
        // background-image: url("../../../public/images/imgbgedit.svg");
        // // @include background-img;
        min-height: 40vh;
        border-radius: 0px;

        @include mq($md) {
          border-radius: 0px;
        }

        // aspect-ratio: 1/1;
        // height: auto;
        .btn {
          position: absolute;
          max-height: 35px;
          max-width: 35px;
          cursor: pointer;

          @include mq($lg) {
            max-height: 30px;
            max-width: 30px;
          }

          @include mq($md) {
            max-height: 25px;
            max-width: 25px;
          }

          @include mq($xls) {
            max-height: 15px;
            max-width: 15px;
          }
        }

        .top {
          top: 10px;
        }

        .left {
          left: 10px;
        }

        .right {
          right: 10px;
        }

        .bottom {
          bottom: 10px;
        }
      }

      .imgBgOptions {
        display: flex;
        margin-top: 10px;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        @include mq($lg) {
          gap: 5px;
        }

        .setting {
          display: flex;
          flex: 1 1 23%;
          gap: 4px;
          max-width: fit-content;
          font-size: 16px;
          font-family: $sf-pro-medium;
          align-items: center;

          @include mq($xlg) {
            font-size: 12px;
          }

          @include mq($lg) {
            font-size: 11px;
          }

          @include mq($xls) {
            font-size: 10px;
          }

          .link {
            max-height: 10px;
            max-width: 20px;
            cursor: pointer;

            @include mq($md) {
              max-width: 15px;
            }
          }

          .input {
            height: 50px;
            border-radius: 0px;
            background: #f0eefe;
            display: flex;
            align-items: center;
            padding: 0 2px;
            gap: 5px;

            @include mq($xlg) {
              border-radius: 0px;
              height: 40px;
            }

            @include mq($lg) {
              gap: 3px;
              font-size: 11px;
            }

            @include mq($md) {
              border-radius: 0px;
              height: 30px;
            }

            @include mq($xls) {
              border-radius: 0px;
              height: 30px;
            }

            .arrow {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}