@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

#orders {
  :global {

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 2fr 1fr;
      align-items: center;
      border-bottom: 1px rgba($color: $white, $alpha: .25) solid;
    }

    .header-item {
      font-family: $sf-pro-bold;
      font-style: normal;
      font-size: pxToEm(20);
      line-height: 1;
      color: $white;
      padding-bottom: 16px;
      display: flex;

      &:last-child {
        justify-content: flex-end;
      }
    }

    .row-item {
      @extend .header-item;
      font-family: $sf-pro-regular;
      font-size: pxToEm(16);
      padding-top: 16px;
    }

    .view-order {
      border: none;
      outline: none;
      background: $background;
      height: 50px;
      width: 150px;
      display: grid;
      place-items: center;
      margin: 0;
      padding: 0;
      font-family: $sf-pro-bold;
      font-style: normal;
      font-weight: 700;
      font-size: pxToEm(14);
      line-height: 1;
      color: $white;
      @include transition;
      cursor: pointer;

      &:hover {
        background: lighten($color: $background, $amount: 6);
      }
    }

    span {
      font-family: $sf-pro-bold;
      font-style: normal;
      color: $white;
    }

    .status-chip {
      display: flex;
      gap: 12px;
      padding: 12px;
      align-items: center;
      background: $black;
    }
  }
}