@import "../../../../../../sass/variables.scss";
@import "../../../../../../sass/mixins.scss";

#loginOrSignup {
    :global {
        width: 100vw;
        height: 100vh;
        background: transparent;
        /* Note: currently only Safari supports backdrop-filter */
        backdrop-filter: blur(30px);
        --webkit-backdrop-filter: blur(30px);
        background-color: rgba($color: $black, $alpha: 0.8);
        /* (plus shape's fill blended on top as a separate layer with 82% opacity) */
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        display: grid;
        place-items: center;

        .popup-card {
            border-radius: 22px;
            background: $blue;
            padding: 20px 20px 0px 20px;
            max-width: 592px;
            min-width: 592px;
            max-width: 592px;
            display: flex;
            gap: 40px;
            flex-direction: column;
            background-position: 125% 0;
            background-repeat: no-repeat;

            .d-flex {
                display: flex;
            }

            .text-container {
                width: 100%;
                justify-content: space-between;
            }

            .popup-text {
                width: ((414.1/592)*100%);
                font-family: $sf-pro-medium;
                font-size: pxToEm(28);
                line-height: 1.3;
                text-align: left;
                color: #fbfef9;
            }

            .login-account {
                width: 50%;
                border-radius: 20px 20px 0px 0px;
                background: #352c61;
                padding: 16px;

                .login-text {
                    @extend .popup-text;
                    width: 100%;
                    font-size: pxToEm(16);
                    padding-bottom: 20px;
                }

                .login-btn {
                    width: 100%;
                    height: 50px;
                    border-radius: 25px;
                    background: #f0eefe;
                    display: grid;
                    place-items: center;
                    margin-bottom: 12px;
                    cursor: pointer;

                    .login-btn-text {
                        font-family: $sf-pro-regular;
                        font-size: 16px;
                        line-height: 1;
                        text-align: left;
                        color: #352c61;
                    }

                    img {
                        width: 16px;
                        height: auto;
                    }

                    .d-flex {
                        gap: 12px;
                        align-items: center;
                        align-content: center;
                    }
                }


            }

            .signup-color {
                background-color: #E3DEFE;

                .login-text {
                    color: black
                }

                .login-btn {
                    background-color: #6D4EFF;

                    .login-btn-text {
                        color: #F0EEFE;
                    }
                }

            }


        }
    }
}