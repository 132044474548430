@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#imageSliders {
  :global {
    width: 100%;
    border-radius: 0px;
    background-image: url("../../../../../public/images/bg_login.svg");
    @include background-img;
    padding: 5.48148148148148% 24px 2.0% 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    inset: 0;
    @include mq(1600px) {
      padding: 5% 10px 1.0% 10px;
    }
    .carousel .slide{
      color: $white;
      font-weight: bold;
      font-size: pxToVw(50);
      margin-bottom: 10px;
      line-height: 1.1;
      text-align: left;
      @include mq($lg) {
        font-size: 30px;
      }
      @include mq($md) {
        font-size: 25px;
      }
      @include mq($xls) {
        font-size: 20px;
      }
      span{
        color: $blue;
      }

    }
    .box_slider{
      margin: auto 0;
      width: 100%;
      max-height: 90vh;
      display: flex;
      flex-direction: column;
      margin: auto 0;
      justify-content: flex-start;
      img{
        flex-grow: 1;
        max-width: 95%;
        margin: 0 auto;
      }
    }
    .top {
      max-width: 70%;
      background-color: transparent;
      margin: 0;
      @include mq($xlg) {
        max-width: 85%;
      }
      @include mq($md) {
        max-width: 90%;
      }
      @include mq($xls) {
        max-width: 100%;
      }
      .subtitle {
        @include font-size-sub-title;
        color: $blueMd !important;
        max-width: 78%;
        margin: 30px 0;
        display: flex;
        justify-content: flex-start;
        text-align: left;
        @include mq($xlg) {
          margin-bottom: 20px;
        }
        @include mq($md) {
          max-width: 85%;
          margin-bottom: 15px;
        }
        @include mq($xls) {
          max-width: 90%;
          margin-bottom: 10px;
        }
        @include mqh($xls) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
