@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.deletePopup {
  :global {
    .btn_light {
      background-color: $background;
      color: $black;
    }

    .btn_purple {
      background-color: $purple;
      color: $background;
    }

    .loader {
      margin-bottom: 20px;
    }
  }
}