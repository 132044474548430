@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.manageGlb {
  :global {
    @include dashboardPagesBackground;
    height: 100%;
    padding: 20px;
    border-radius: 12px;
    max-width: 100%;


    .numbering {
      display: flex;
      gap: 32px;
    }

    .d-flex {
      display: flex;
      gap: 35px;

      p {
        min-width: 82px;
      }
    }

    a {
      text-decoration: none;
    }

    @include mq($xlg) {
      padding: 15px;
    }

    @include mq($md) {
      padding: 10px;
    }

    .top_content {
      display: flex;
      flex-direction: column;
      gap: 7px;
      margin-bottom: 20px;

      @include mq($md) {
        margin-bottom: 15px;
      }

      @include mq($xls) {
        margin-bottom: 10px;
      }

      .child {
        @include font-size-sub-title;
      }

      .title {
        font-family: $sf-pro-medium;
        font-size: 30px;
        text-align: left;
        color: $black;

        @include mq($xlg) {
          font-size: 25px;
        }

        @include mq($md) {
          font-size: 20px;
        }

        @include mq($xls) {
          font-size: 16px;
        }
      }

      .sub_title {
        font-family: $sf-pro-medium;
        font-size: 30px;
        text-align: left;
        color: $black;
        @include font-size-sub-title;
      }
    }

    .sort_box {
      display: flex;
      align-items: center;
      gap: 2px;
      cursor: pointer;

      .image {
        max-height: 12px;

        @include mq($md) {
          max-height: 10px;
        }
      }
    }

    .uploaded_glb {
      border-radius: 12px;
      flex-grow: 1;
      background-color: $background;
      padding: 15px 12px 15px 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include mq($xlg) {
        padding: 15px 12px 15px 15px;
      }

      @include mqh($md) {
        padding: 10px;
      }

    }

    .product-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      min-height: calc(100vh - 314px);

      .image-preview {
        display: grid;
        grid-template-rows: 1fr 132px;
        gap: 20px;

        .main-image {
          background: white;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          height: 100%;
        }

        .image-to-preview {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          gap: 12px;

          .single-image-to-preview {
            background-color: gray;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }

          .active {
            border: 4px $blue-v2 solid;
          }
        }
      }

      .product-desc {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .flex-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .product-title {
            font-family: $sf-pro-heavy;
            font-style: normal;
            font-weight: 900;
            font-size: pxToEm(60);
            line-height: 1;
            color: $white;
          }

          .product-price {
            font-family: $sf-pro-bold;
            font-style: normal;
            font-weight: 700;
            font-size: pxToEm(28);
            line-height: 1;

            color: $green;
          }
        }

        .item-title {
          font-family: $sf-pro-medium;
          font-style: normal;
          font-weight: 500;
          font-size: pxToEm(18);
          line-height: 1;
          text-transform: uppercase;
          color: $white;
          margin-bottom: 12px;
        }

        .list-of-sizes {
          display: flex;
          gap: 12px
        }

        .single-size {
          width: 104px;
          height: 32px;
          background: #24292E;
          display: grid;
          place-items: center;
          font-family: $sf-pro-medium;
          font-style: normal;
          font-weight: 500;
          font-size: pxToEm(18);
          line-height: 1;
          text-transform: uppercase;
          color: $white;
          cursor: pointer;
        }

        .active-size {
          background-color: $green;
        }

        table,
        {
        font-family: $sf-pro-light;
        border-collapse: collapse;
        width: 100%;
      }

      td,
      th {
        border: 1px solid #24292E;
        text-align: left;
        padding: 8px;
        border-left: none;
        border-right: none;
      }

      th {
        background-color: #24292E;
      }

      tr,
      .description {
        color: $white;
      }

      .btn-group {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        gap: 20px;
        align-items: flex-end;

        .cart-btns {
          display: grid;
          gap: 20px;
        }

        .count-btns {
          display: grid;
          grid-template-columns: 150px 1fr;
          gap: 20px;
        }


        .add-to-cart {
          background: #637887;
          font-family: $sf-pro-bold;
          font-style: normal;
          font-weight: 700;
          font-size: pxToEm(14);
          line-height: 1;
          text-transform: uppercase;
          color: $white;
          outline: none;
          height: 50px;
          display: grid;
          place-items: center;
          border: none;
          width: 100%;
          @include transition;

          &:hover {
            background: lighten($color: #637887, $amount: 10)
          }
        }

        .edit-design {
          @extend .add-to-cart;
          background: $purple;
          @include transition;

          &:hover {
            background: lighten($color: $purple, $amount: 10)
          }
        }

        .order-now {
          @extend .add-to-cart;
          background: $green;
          @include transition;

          &:hover {
            background: lighten($color: $green, $amount: 10)
          }
        }

        .counter {
          .cont {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 12px;
          }

          padding: 14px 0px;
          font-family: $sf-pro-bold;
          font-style: normal;
          font-weight: 800;
          font-size: pxToEm(14);
          line-height: 1;
          color: $white;
          width: 100%;
          background: #2D3339;
        }
      }

    }

    .description {
      max-height: 45vh;
      overflow-y: scroll;

      @include scroll-bar;

      * {
        color: $white;
        font-size: pxToEm(16);
        font-family: $sf-pro-light;
      }
    }
  }

}
}