@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

#home {
  :global {
    position: absolute;
    inset: 0;
    background-image: url("../../../public/images/bg-Pag.svg");
    border: none;
    z-index: 2;
    @include page-setting;
    padding: 15px !important;
    margin: 0;
    @include background-img;
    @include mq($xlg) {
      padding: 30px;
    }
    @include mq($lg) {
      padding: 2%;
    }
    @include mq($md) {
      padding: 15px;
    }
    @include mq($xls) {
      padding: 10px;
    }
    .pageBody {
      @include pageBody;
      .leftPagePart,
      .rightPagePart {
        @include bodyChilds;
      }
      .rightPagePart {
        min-height: calc(100vh - 170px);
        max-height: calc(100vh - 170px);
        overflow-y: scroll;
        @include scroll-bar;
      }
    }
  }
}
