@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.topNav {
  :global {
    background-image: url("../../../public/images/bg_nav.png");
    width: 100%;
    border-radius: 0px 0px 0 0;
    height: 76px;
    padding: 0;
    position: relative;
    background-repeat: no-repeat;
    background-size: 150%;
    background-position-x: 20%;

    @include mq($xlg) {
      height: 60px;
    }

    @include mq($lg) {
      height: 50px;
    }

    @include mqh($xls) {
      height: 50px;
    }

    @include mqh($sm) {
      height: 40px;
    }

    .navBox {
      position: absolute;
      inset: 0;
      border-radius: 0px 0px 0 0;
      margin: 0;
      background-color: rgba($color: $black, $alpha: 0.3);
      padding: 0 20px;
      display: flex;
      align-items: center;
      gap: 10%;
      background-blend-mode: difference;

      @include mq($xlg) {
        gap: 11.09062693978895%;
        padding: 0 15px;
      }

      @include mq($lg) {
        gap: 10.09062693978895%;
        padding: 0 10px;
      }

      @include mq($md) {
        gap: 2.09062693978895%;
      }

      .arrow_box {
        background-color: $black;
        border-radius: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include transition;
        height: 59.21052631578947%;
        aspect-ratio: 1/1;
        width: auto;

        &:hover {
          background-color: darken($color: $black, $amount: 30);
        }

        @include mq($md) {
          display: none;
        }

        .arrow {
          max-width: 80%;
          max-height: 90%;
        }
      }

      .search-filter {
        width: 38.20235878336437%;
        height: 59.21052631578947%;
        background-color: $black;
        display: grid;
        grid-template-columns: 2fr 1fr;
        justify-content: flex-start;
        align-items: center;
        padding: 0 30px;
        border-radius: 0px;
        gap: 0;
        color: $white;

        @include mq($xlg) {
          padding: 0 20px;
        }

        @include mq($lg) {
          padding: 0 15px;
        }

        @include mq($md) {
          padding: 0 10px;
          flex-grow: 1;
        }

        .search,
        .filter {
          border-radius: 0px 0 0 0px;
          height: 100%;
          font-family: $sf-pro-medium;
          color: $white;
          border: none;
          background-color: transparent;
          border-right: 1px solid $white;
          @include font-size-sub-title;

          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }

          &::placeholder {
            color: rgba($color: $white, $alpha: 0.5);
            font-family: $sf-pro-medium;
          }
        }

        .filter {
          border: none;
          padding-left: 10px;
        }
      }

      .profile {
        width: 45.30540037243948%;
        background-color: $background;
        display: grid;
        grid-template-columns: 3fr 3fr 3fr;
        align-items: center;
        justify-content: center;
        padding: 0 5px !important;
        border-radius: 0px;
        gap: 5px;
        color: $white;

        .d-flex {
          display: flex;
          gap: 15px;
          align-items: center;
          margin-left: 20px;
        }

        * {
          font-family: $sf-pro-medium;
          margin: 0;
        }

        @include mq($lg) {
          gap: 4px;
          padding: 0 4px;
        }

        @include mq($md) {
          gap: 3px;
          padding: 0 3px;
          flex-grow: 1;
        }

        .button {
          padding: 0 5%;
          border-radius: 0px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $black;
          color: $white;
          min-height: 77%;
          max-height: 77.77777777777778%;
          cursor: pointer;
          @include transition;

          &:hover {
            background-color: lighten($color: $black, $amount: 30);
          }

          @include mq($xlg) {
            font-size: 11px;
          }

          @include mq($lg) {
            font-size: 10px;
          }
        }

        .active {
          background-color: $purple !important;
          color: $white;
        }

        .name {
          font-size: 14px;
          display: flex;
          justify-content: flex-end;

          @include mq($xlg) {
            font-size: 13px;
          }

          @include mq($lg) {
            font-size: 12px;
          }

          @include mq($md) {
            font-size: 11px;
          }
        }

        .imgprofile {
          display: flex;
          justify-content: flex-end;
          max-height: 77.77777777777778%;
          aspect-ratio: 1/1;

          .picture {
            background-color: $blue;
            cursor: pointer;
            min-height: 50px;
            // max-width: 100%;
            border-radius: 0%;
            margin: 0;
            aspect-ratio: 1/1;
            width: auto;
            padding: 0;
            border-radius: 0%;
            @include background-img;
          }
        }
      }
    }

    .info-section {
      background: $background;
      padding: 5px;
      display: flex;
      gap: 5px
    }

    .info-box {
      background: $black;
      height: 35px;
      width: 35px;
      display: grid;
      place-items: center;
    }
  }
}