@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";
#configurator {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px;

    :global {
        canvas{
            border-radius: 0px;
        }
        .preloader-container {
            border-radius: 0px;
            backdrop-filter: blur(30px);
            background-color: rgba($color: $black, $alpha: 0.06);
            display: grid;
            place-items: center;
            position: absolute;
            .CircularProgressbar-path {
                stroke: $purple;
            }

            .CircularProgressbar-text{
                color:$white;
                fill:$white;
                font-family: $sf-pro-medium;
                font-size: pxToVw(16);
            }

            .CircularProgressbar-trail {
                stroke: $background;
            }

        }
    }

    .preloader {
        width: 90px;
        height: 90px;
        background: transparent;
    }
}