@import "./sass/variables.scss";
@import "./sass/mixins.scss";
@import "./src/pages/UploadGlbPage/subPages/uploadGlb/FormUpload.module.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: $white;
  @extend .formUpload;
  @include scroll-bar;

  .app {
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-x: hidden;
    align-items: center;
    max-height: fit-content;
    max-height: fit-content;
    @include scroll-bar;
  }


  // modifying the dshabord pages so they are constitent
  .top_content .title,
  .sub_title {
    color: rgba($color: $white, $alpha: 0.75) !important;
    ;
  }

  .top_content .label {
    color: rgba($color: $white, $alpha: 0.75) !important;
  }

  .instructions .label {
    color: rgba($color: $white, $alpha: 0.75) !important;
  }

  .form_content .labelBox .label {
    color: rgba($color: $white, $alpha: 0.75) !important;
  }

  .preview,
  .preview_img,
  .title {
    color: rgba($color: $white, $alpha: 0.75) !important;
  }

  .uploaded_glb {
    border-radius: 0px !important;
    background-color: rgba($color: $background, $alpha: 0.6) !important;
  }

  .final {
    background-color: $white !important;
    border-radius: 0px !important;

    &:hover {
      background-color: darken($color: $white, $amount: 30) !important;
    }
  }

  .draft {
    border-radius: 0px !important;
  }

  .uploaded_glb {

    .uploaded_glb_top .search_input,
    h5 {
      color: rgba($color: $white, $alpha: 0.75) !important;

      &::placeholder {
        color: rgba($color: $white, $alpha: 0.2) !important;
      }
    }

    .uploaded_glb_body .content_box .row {
      border-top: solid 1px rgba($color: $white, $alpha: 0.2) !important;
    }

    .child {
      color: rgba($color: $white, $alpha: 0.75) !important;
    }

    .edit {
      color: $blue !important;
      @include transition;

      &:hover {
        color: lighten($color: $blue, $amount: 30) !important;
        border-color: lighten($color: $blue, $amount: 30) !important;
      }
    }

    .delete {
      color: $red !important;
      border-color: $red !important;
      @include transition;

      &:hover {
        color: lighten($color: $red, $amount: 30) !important;
        border-color: lighten($color: $red, $amount: 30) !important;
      }
    }

    .preview {
      color: $s_blue !important;
      border-color: $s_blue !important;
      @include transition;

      &:hover {
        color: lighten($color: $s_blue, $amount: 30) !important;
        border-color: lighten($color: $s_blue, $amount: 30) !important;
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.iconBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  @include mq($md) {
    padding-bottom: 15px;
  }

  @include mqh($md) {
    gap: 15px;
    padding-bottom: 15px;
  }

  @include mqh($sm) {
    gap: 10px;
  }

  .SinglIcon {
    width: 70px;
    aspect-ratio: 1/1;
    height: auto;
    border-radius: 0%;

    @include mq($xlg) {
      width: 50px;
    }

    @include mq($lg) {
      width: 45px;
    }

    @include mq($lg) {
      width: 40px;
    }
  }
}

.formSave {
  display: flex;
}

div[about="checkout"] {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #24292e;
  }

  input {
    background: #24292e;
    border: none;
    outline: none;
  }
}

.error-text {
  color: #EB5D5D;
  font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-left: 0px;
  margin-right: 0px;
}