@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#CartCalculation {
    padding: 32px 24px 28px 24px;
    background: #24292E;

    :global {
        .items {
            display: grid;
            gap: 10px;
            margin-bottom: 48px;
        }

        .single-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        .i-name {
            font-family: $sf-pro-regular;
            font-style: normal;
            font-size: pxToEm(17);
            line-height: 100%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $white;
        }

        .i-price {
            font-family: $sf-pro-bold;
            font-style: normal;
            font-size: pxToEm(17);
            line-height: 100%;
            text-align: right;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $white;
        }

        .checkout-btn {
            width: 100%;
            height: 52px;
            border: none;
            text-decoration: none;
            outline: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: $blue-v2;
            font-family: $sf-pro-medium;
            font-style: normal;
            font-weight: 500;
            font-size: pxToEm(20);
            line-height: 100%;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $white;
        }
    }
}