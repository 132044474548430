@font-face {
  font-family: sfProDisplayLight;
  src: url("./fonts/AvertaCY-Light.woff2");
}

@font-face {
  font-family: sfProDisplayRegular;
  src: url("./fonts/AvertaCY-Regular.woff2");
}

@font-face {
  font-family: sfProDisplayMedium;
  src: url("./fonts/SFProDisplay-Medium.ttf");
}

@font-face {
  font-family: sfProDisplaySemiBold;
  src: url("./fonts/AvertaCY-Semibold.woff2");
}

@font-face {
  font-family: sfProDisplayBold;
  src: url("./fonts/AvertaCY-Bold.woff2");
}

@font-face {
  font-family: sfProDisplayHeavy;
  src: url("./fonts/SFProDisplay-Heavy.ttf");
}

@font-face {
  font-family: poppinsblack;
  src: url("./fonts/POPPINS-BLACK.TTF");
}

@font-face {
  font-family: poppinsBold;
  src: url("./fonts/POPPINS-BOLD.TTF");
}

@font-face {
  font-family: poppinsEXTRABOLD;
  src: url("./fonts/POPPINS-EXTRABOLD.TTF");
}

@font-face {
  font-family: poppinsEXTRALIGHT;
  src: url("./fonts/POPPINS-EXTRALIGHT.TTF");
}

@font-face {
  font-family: poppinsLight;
  src: url("./fonts/POPPINS-LIGHT.TTF");
}

@font-face {
  font-family: poppinsMEDIUM;
  src: url("./fonts/POPPINS-MEDIUM.TTF");
}

@font-face {
  font-family: poppinsSEMIBOLD;
  src: url("./fonts/POPPINS-SEMIBOLD.TTF");
}

@font-face {
  font-family: poppinsTHIN;
  src: url("./fonts/POPPINS-THIN.TTF");
}
@font-face {
  font-family: Bogam;
  src: url("./fonts/bogam.OTF");
}

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap')