@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

#headerText {
    font-family: $urbanist;
    font-weight: 300;
    font-size: 77px;
    text-align: left;
    color: $white;

    @include mq($xlg) {
        font-size: 30px;
    }
}