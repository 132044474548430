@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

#FeatureDisplay {
    @include landingPadding;
}

#divider {
    height: pxToEm(190);
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include mq($xlg) {
        height: pxToEm(190/2);
    }

    :global {
        .divider-line {
            width: pxToVw(1563);
            height: 14px;
            border-radius: 7px;
            background: linear-gradient(90deg, #ff665f 0%, #ac74d0 31.8%, #5da2f2 65.94%, #00ffd0 100%);
            position: absolute;
            right: 0
        }
    }
}