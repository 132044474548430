@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#successSubmition {
  :global {
    position: absolute;
    inset: 0;
    padding: 20px;
    margin: 0;
    z-index: 2;
    overflow-x: hidden;
    background-color: $background;
    background-image: url("../../../../../public/images/bgsubmission.svg");
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: pxToVh(122);
    @include background-img;

    @include mq($xlg) {
      padding: 15px;
    }

    @include mq($xls) {
      padding: 10px;
    }

    .body {
      max-height: pxToVh(676);
      border-radius: 15px;
      border: 1px solid $gray;
      background-color: $background;
      padding: 0 0 20px 0;
      max-width: pxToVw(335);
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include mq($xlg) {
        padding: 0 0 15px 0;
      }

      @include mq($lg) {
        padding: 0 0 15px 0;
        gap: 7px;
      }

      @include mq($md) {
        padding: 0 0 15px 0;
      }

      @include mqh($xls) {
        padding: 0 0 10px 0;
        gap: 5px;
      }

      .centered {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        flex-grow: 1;

        * {
          flex-grow: 1;
        }
      }

      .title {
        font-weight: normal;
        font-size: pxToEm(22);
        display: flex;
        justify-content: center;
      }

      .imgTop {
        width: pxToVw(335);
        height: pxToVw(335);
        flex-grow: 1;

        @include mq($xlg) {
          width: pxToVw(400);
          height: pxToVw(400);
        }

        @include mq($lg) {
          width: 100%;
        }

        border: 1px solid $gray;
        border-radius: 15px;
        border-bottom: none;
        border-left: none;
        border-right: none;

        background-image: url("../../../../../public/images/confirmed_bg.svg");
        @include background-img;
      }

      .bottom {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 15px;
        gap: 10px;

        @include mq($xlg) {
          gap: 7px;
          margin: 10px;
        }

        @include mq($md) {
          gap: 5px;
          margin: 8px;
        }

        .centered {
          width: 100%;
          margin: 0;
          font-weight: 500;
          font-size: pxToEm(17.72);
          line-height: 1.3;
          text-align: center;
        }
      }
    }
  }
}