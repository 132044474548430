@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
#customizeStyler {
  :global {
    height: 100%;
    width: 100%;
    min-width: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 22px;
    row-gap: 20px;
    @include mq($lg) {
      column-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 15px;
    }
    @include mq($lg) {
      column-gap: 18px;
      grid-template-columns: 1fr 1fr;
      row-gap: 18px;
    }
    @include mq($md) {
      column-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 15px;
    }
    @include mq($xls) {
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      column-gap: 10px;
    }
    .card {
      aspect-ratio: 1/1;
      height: auto;
      border-radius: 0px;
      padding: 10px 7px;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
      @include transition;
      @include mq($lg) {
        padding: 7px;
      }
      @include mq($lg) {
        padding: 5px;
      }
      @include mq($md) {
        border-radius: 0px;
      }
      .card-top {
        display: flex;
        gap: 10px;
        z-index: 5;
        background-color: transparent;
        justify-content: flex-end;
        @include mq($lg) {
          gap: 8px;
        }
        @include mq($md) {
          gap: 5px;
        }
        .boxActions {
          height: 45px;
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $white;
          border-radius: 0px;
          @include mq($xlg) {
            height: 30px;
            border-radius: 0px;
          }
          @include mq($md) {
            height: 30px;
            border-radius: 0px;
          }
          .logoIcon {
            max-height: 100%;
            max-width: 100%;
          }
          .bodyCardSelect {
            font-family: $sf-pro-medium;
            font-weight: 900;
            font-size: 14px;
            text-align: left;
            color: $black;
            @include mq($xlg) {
              font-size: 10px;
            }
            @include mq($lg) {
              font-size: 8px;
            }
          }
          .edit {
            cursor: pointer;
            position: relative;
            border-radius: 0%;
            height: 100%;
            aspect-ratio: 1/1;
            width: auto;
            background-color: $purple;
            display: flex;
            justify-content: center;
            align-items: center;

            .editIcon {
              cursor: pointer;
              z-index: 1;
              max-height: 60%;
              max-width: 60%;
              @include mq($xlg) {
                max-height: 50%;
                max-width: 50%;
              }
            }
            .edit-blur {
              position: absolute;
              inset: 0;
              border-radius: 0%;
              height: 100%;
              z-index: 0;
              background: transparent;
              backdrop-filter: blur(11px);
              --webkit-backdrop-filter: blur(11px);
              background-color: rgba(255, 255, 255, 0.03);
              pointer-events: none;
              cursor: pointer;
            }
          }
        }
        .icon {
          height: 45px;
          width: auto;
          aspect-ratio: 1/1;
          border-radius: 0%;
          background-color: #ebc8c8;
          @include mq($xlg) {
            height: 30px;
          }
        }
      }
      .compontent {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        inset: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        border-radius: 0px;
        model-viewer{
          border-radius: 0px;
        }
        @include mq($md) {
          border-radius: 0px;
          z-index: 0;
        }
      }
      .card-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        .number {
          font-family: $sf-pro-bold;
          font-weight: 500;
          font-size: pxToVw(49);
          text-align: left;
          color: $white;
          @include mq($lg) {
            font-size: pxToVw(40);
          }
          @include mq($md) {
            font-size: pxToVw(35);
          }
        }
        .Name {
          font-family: $poppinsMEDIUM;
          font-weight: 500;
          font-size: pxToVw(16);
          text-align: right;
          color: $white;
          @include mq($md) {
            font-size: pxToVw(14);
          }
        }
      }
      
    }
  }
}
