@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
#selectCardTexture {
  :global {
    padding: 0;
    margin: 0;
    max-height: 100%;
    border-radius: 0px;
    padding: 10px 7px;
    background-color: $white;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 13;
    bottom: 95%;
    min-width: 95%;
    left: 0;
    right: 0;
    .option {
      display: flex;
      gap: 10px;
      z-index: 0;
      font-weight: 900;
      border: none;
      background-color: transparent;
      align-items: center;
      cursor: pointer;
      font-size: 14;
      @include transition; 
      .logo {
        max-width: 35px;
        max-height: 35px;
        @include mq($lg) {
          max-width: 25px;
          max-height: 25px;
        }
        @include mq($md) {
          max-width: 20px;
          max-height: 20px;
        }
        @include mq($xls) {
          max-width: 15px;
          max-height: 15  px;
        }
      }
      @include mq($xlg) {
        border-radius: 0px;
      }
      @include mq($lg) {
        border-radius: 0px;
      }
      @include mq($md) {
        border-radius: 0px;
      }
     
      .checkBox {
        display: none;
      }
      .box{
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
      }
      &:hover{
        .checkBox {
          display: flex;
        }
      }
    }
  }
}
