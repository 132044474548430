@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

#allGameGlb {
    :global {
        .top-text {
            display: grid;
            grid-template-columns: 1fr 7fr 1fr 1fr 1fr;
            gap:32px;
            color: $white;
            font-family: $sf-pro-medium;
            font-size: 1em;
            line-height: 1;
            padding-bottom: 20px;
            border-bottom: solid gray 1px;
        }

        .theListOfInfo {
            @extend .top-text;
            padding-top: 20px;
        }
    }
}