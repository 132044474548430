@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

#SingleMatch {
    width: "100%";
    display: flex;
    align-items: center;

    $height: 35vh;

    :global {
        .glbName {
            width: "100%";
            height: 48px;
            display: grid;
            place-items: center;
            background-color: $black;
            color: $white;
            font-family: $sf-pro-medium;
            font-size: 1em;
            line-height: 1;
        }

        .patternName {
            @extend .glbName;
            background-color: $blueOpaque;
            height: 24px;
            font-size: pxToEm(10);
        }

        .glb-container {
            width: 100%;
            height: $height;
            background: gray;
        }

        svg {
            min-width: 63px;
            height: auto;
        }

        .pattern-container {
            width: 100%;
            height: auto;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            // grid-template-rows: 115px 115px 115px;
            gap: 2px;
            background: #282E33;

            .single-svg-pattern {
                width: 100%;
                height: 115px;
                background: #D9D9D9;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }

            .active {
                width: 100%;
                height: 100%;
                background: #5D3FD3;
                opacity: 0.5;
            }
        }
    }
}