@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

#hero {

    :global {
        width: 100vw;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .hero-text {
            padding-bottom: pxToVh(310);
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $urbanist;
            font-weight: 600;
            font-size: 77px;
            text-align: left;
            color: $white;
            text-shadow: 0px 3px 6px rgba($color: $black, $alpha: .2);
            line-height: 92/77;

            @include mq($xlg) {
                font-size: 38px;
                padding-bottom: pxToVh(200);
            }
        }

        .cta {
            padding-bottom: 80px;
            display: flex;
            justify-content: flex-end;

            @include mq($xlg) {
                padding-bottom: 20px;
            }

            .cta-btn {
                border-radius: 56px 0px 0px 56px;
                background: $green-gradient;
                opacity: 0.77;
                padding: 32px 59px 32px 47px;
                border: none;
                color: $white;
                font-family: $urbanist;
                font-weight: 500;
                font-size: pxToEm(40);
                text-align: left;
                color: $white;

                @include mq($xlg) {
                    padding: 15px 15px 15px 15px;
                    font-size: pxToEm(20);
                }
            }
        }
    }
}