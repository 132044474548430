@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#order {
    padding: 20px;
    max-width: 400px;
    min-width: 400px;
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(14px);
    --webkit-backdrop-filter: blur(14px);
    background-color: $black;
    /* (plus shape's fill blended on top as a separate layer with 42% opacity) */
    display: grid;
    flex-direction: column;
    gap: 28px;
    border: none;
    outline: none;

    :global {
        h3 {
            font-family: $sf-pro-medium;
            font-weight: 500;
            font-size: 20px;
            text-align: left;
            color: rgba($color: $white, $alpha: 0.75);
            margin: 0px;
            margin-bottom: 8px;
        }

        h6 {
            font-family: $sf-pro-light;
            font-weight: 500;
            font-size: 14px;
            text-align: left;
            color: $white;
            margin: 0px;
            margin-bottom: 4px;
        }

        hr {
            width: 100%;
            height: 2px;
            background-color: #a2a2a2;
            border: none;
            outline: none;
            margin-bottom: 8px;
        }

        .flex {
            display: flex;
            justify-content: space-between;
        }

        img {
            width: auto;
            height: 36px;
        }

        .img-group {
            display: flex;
            gap: 30px
        }
    }
}