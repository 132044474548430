@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

#checkout {
  display: grid;
  grid-template-columns: 0.69fr 0.31fr;
  gap: 31px;
  height: auto;

  :global {
    .heading {
      font-size: 13.5px;
      color: $white;
      margin-top: 25px;
      margin-bottom: 27px;
      font-family: $sf-pro-semi-bold;
      line-height: 1;
    }

    label {
      font-size: 13.5px;
      color: $white;
      font-family: $sf-pro-semi-bold;
      line-height: 1;

      .required {
        &::after {
          content: "*";
          color: $red;
          margin-left: 12px;
        }
      }
    }

    .form-item {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 100%;
      margin-bottom: 28px;
    }

    .form-flex {
      display: flex;
      gap: 29px;
    }

    .check-box-flex {
      display: flex;
      gap: 13px;
      align-items: center;
      margin-bottom: 28px;
    }

    textarea {
      background: #24292E;
      border-radius: 0px;
      border: none !important;
      outline: none !important;
      padding: 15px 28px;

      font-family: $sf-pro-semi-bold;
      font-style: normal;
      font-weight: 600;
      font-size: 14.5px;
      line-height: 17px;
      display: flex;
      align-items: center;

      color: rgba(255, 255, 255, 1);
    }

    .checkout-cart {
      background: #404952;
      padding: 42px 32px;
      transform: translateY(-20px);

      .title-head {
        font-family: $sf-pro-bold;
        font-style: normal;
        font-weight: 700;
        font-size: 16.5px;
        line-height: 1;
        display: flex;
        align-items: center;
        color: $white;
        margin-bottom: 28px;
      }

      table {
        border-collapse: collapse;
        width: 100%;
      }

      td {
        min-height: 30px;
        font-family: $sf-pro-bold;
        font-style: normal;
        font-weight: 700;
        font-size: 13.5px;
        line-height: 3;
        color: $white;

        &:last-child {
          text-align: right;
        }
      }

      tbody {
        td {
          font-family: $sf-pro-regular;
          font-weight: 400;
        }
      }

      .final-cost {
        display: flex;
        justify-content: space-between;
        @extend label;
        padding: 25px 0px;
        border-bottom: 1px solid white;
      }

      .no-flex {
        display: block;
      }

      .table {
        margin-top: 20px;
      }

      .price-for-shipping {
        min-height: 42px;
        display: grid;
        place-items: center;
      }

      .bottom-el {
        margin-top: 44px;
      }

      .place-order {
        border: none;
        outline: none;
        background: $black;
        border-radius: 0px;
        font-family: $sf-pro-semi-bold;
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 16px;
        display: grid;
        place-items: center;
        color: #D5E0DE;
        min-height: 50px;
        min-width: 100%;
        margin-top: 32px;
      }
    }
  }
}