@import "../sass/variables.scss";
@import "../sass/mixins.scss";

.RightPageDetails {
  :global {
    @include dashboardPagesBackground;
    height: 100%;
    padding: 20px;
    border-radius: 12px;
    max-width: 100%;


    .top_content {
      display: flex;
      flex-direction: column;
      gap: 7px;
      margin-bottom: 20px;

      @include mq($md) {
        margin-bottom: 15px;
      }

      @include mq($xls) {
        margin-bottom: 10px;
      }

      .child {
        @include font-size-sub-title;
      }

      .title {
        font-family: $sf-pro-medium;
        font-size: 30px;
        text-align: left;
        color: $black;

        @include mq($xlg) {
          font-size: 25px;
        }

        @include mq($md) {
          font-size: 20px;
        }

        @include mq($xls) {
          font-size: 16px;
        }
      }

      .sub_title {
        font-family: $sf-pro-medium;
        font-size: 30px;
        text-align: left;
        color: $black;
        @include font-size-sub-title;
      }
    }

    .uploaded_glb{
        padding: 20px;
    }
    

  }
}