@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
.cardPopUpHover {
  :global {
    display: flex;
    position: absolute;
    inset: 0;
    background-color: transparent;
    @include transition;
    .bgBlur {
      position: absolute;
      z-index: 10;
      inset: 0;
      border-radius: 0px;
      background: transparent;
      backdrop-filter: blur(5px);
      --webkit-backdrop-filter: blur(5px);
      background-color: rgba(255, 255, 255, 0.15);
      @include mq($md) {
        border-radius: 0px;
      }
    }
    .bgTransparent {
      position: absolute;
      inset: 0;
      z-index: 15;
      background-color: transparent;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .btn {
        height: 45px;
        width: 72.6%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5%;
        padding: 0 20px;
        font-family: Poppins;
        font-weight: 500;
        font-size: 16px;
        text-align: left;
        color: $white;
        cursor: pointer;
        border-radius: 0px;
        @include mq($xlg) {
          height: 40px;
          font-size: 14px;
        }
        @include mq($lg) {
          height: 35px;
          padding: 0 17px;
          font-size: 12px;
          border-radius: 0px;
        }
        @include mq($md) {
          height: 30px;
          font-size: 11px;
          padding: 0 20px;
          border-radius: 0px;
        }
        @include mq($sm) {
          height: 25px;
          font-size: 10px;
          border-radius: 0px;
        }
      }
      .edit {
        background-color: $black;
      }
      .use {
        background-color: $blue;
      }
    }
  }
}
