@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#SingleCartItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 2px rgba($color: $white, $alpha: .25) solid;

    :global {
        .p-s-details {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .cart-img {
            height: 76px;
            width: 76px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .p-name {
            font-family: $sf-pro-semi-bold;
            font-style: normal;
            font-size: pxToEm(16);
            line-height: 1;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $white;
            margin-bottom: 4px;
        }

        .p-size {
            font-family: $sf-pro-bold;
            font-style: normal;
            font-size: pxToEm(14);
            line-height: 1;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: rgba($color: $white, $alpha: .5);
        }

        .p-count {
            display: flex;
            gap: 5px;
            align-items: center;
            svg{
                path{
                    cursor: pointer;
                }
            }
        }

        .p-price {
            font-family: $sf-pro-bold;
            font-style: normal;
            font-size: pxToEm(14);
            line-height: 100%;
            text-align: right;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: $white;
        }

        .p-delete {

            svg {
                @include transition;
                cursor: pointer;
                &:hover {
                    path {
                        fill: $red;
                    }
                }
            }
        }
    }
}