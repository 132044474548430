@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

#OrdersDetails {
  :global {

    .current-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 0px 20px;
      gap: 10px;
      font-family: $sf-pro-regular;
      font-style: normal;
      font-size: pxToEm(16);
      line-height: 1;
      color: $white;

      span {
        font-family: $sf-pro-heavy;
      }
    }

    .od-title {
      font-family: $sf-pro-bold;
      font-style: normal;
      font-size: pxToEm(30);
      line-height: 1;
      color: $white;
      margin-bottom: 16px;
    }

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-content: space-between;
      border-bottom: 1px rgba($color: $white, $alpha: .25) solid;
      padding-bottom: 16px;
      padding-top: 16px;
      align-items: center;
    }

    .row-item {
      font-family: $sf-pro-semi-bold;
      font-style: normal;
      font-size: pxToEm(20);
      line-height: 1;
      color: $white;
      display: flex;
      justify-content: flex-end;

      &:first-child {
        justify-content: flex-start;
      }
    }

    .od-grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 20px;
    }

    .body-row-item {
      font-family: $sf-pro-light;
      font-style: normal;
      font-size: pxToEm(16);
      line-height: 1;
      color: $white;
      display: flex;
      justify-content: flex-end;

      &:first-child {
        justify-content: flex-start;
      }
    }


    .product-info {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .img-bg {
      height: 76px;
      width: 76px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .od-footer {
      margin-top: 32px;
      display: grid;
      gap: 12px;
    }

    .og-f-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .name {
        font-family: $sf-pro-bold;
        font-style: normal;
        font-size: pxToEm(20);
        color: $white;
      }

      .value {
        font-family: $sf-pro-light;
        font-size: pxToEm(20);
        line-height: 1;
        color: $white;

        b {
          font-family: $sf-pro-heavy;
        }
      }
    }

    .ship-det {
      background: $background;
      width: 100%;
    }

    .s-det {
      padding: 20px;
      display: grid;
      gap: 16px;

      section {
        padding-bottom: 16px;
        border-bottom: 1px rgba($color: $white, $alpha: .25) solid;

        .s-det-title {
          font-family: $sf-pro-bold;
          font-style: normal;
          font-size: pxToEm(24);
          line-height: 1;
          color: $white;
          margin-bottom: 8px;
        }

        .sub-title {
          font-family: $sf-pro-light;
          font-style: normal;
          font-size: pxToEm(16);
          line-height: 19/16;
          color: $white;
          opacity: 50%;
          b{
            text-transform: capitalize;
            font-family: $sf-pro-medium;
            line-height: 2
          }
        }

        .info-fg {
          @extend .sub-title;
          opacity: 1;
        }
      }

      .small-details {
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }
  }
}