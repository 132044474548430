@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

.form {
  :global {
    width: 100%;
    padding: 0;
    background-color: transparent;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px $black inset !important;
      transition: background-color 5000s ease-in-out 0s !important;
      padding: 0px;
      -webkit-text-fill-color: $white;
    }

    .inputBox {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 10px;
      margin-top: 10px;
      width: 100%;
      padding: 0;
      background-color: transparent;
    }

    .checkbox {
      display: flex;
      margin: 10px 0 0 0;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      width: 100%;

      @include mq($lg) {
        gap: 10px;
      }

      @include mq($md) {
        gap: 6px;
      }

      .checkinfo {
        display: flex;
        gap: 3px;
        flex-grow: 1;
      }
    }

    .linkTo {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 30px;
      gap: 3px;


    }

    .link {
      cursor: pointer;
      color: $s_blue !important;
      @include transition;

      &:hover {
        color: lighten($color: $s_blue, $amount: 6);
      }
    }
  }
}