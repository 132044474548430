@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
#hapePageRight {
  :global {
    min-height: calc(100% - 20px);
    max-height: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include mq($xlg) {
      min-height: calc(100% - 15px);
    }
    @include mq($lg) {
      min-height: calc(100% - 10px);
    }
    @include mq($md) {
      min-height: calc(100% - 8px);
    }
    gap: 0;
    .hape-title {
      margin-bottom: 14px;
      color: $white;
      font-family: $sf-pro-medium;
      font-weight: 500;
      font-size: pxToVw(50);
      text-align: left;
    }
   
    .hape-body {
      display: flex;
      flex-direction: column;
      gap: 0;
      padding: 0;
      flex-grow: 1;
      background-color: $white;
      border-radius: 0px;
      .hape-body-top {
        background-color: $black;
        color: $white;
        border-radius: 0px 0px 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        padding: 18px 20px;
        @include mq($lg) {
          border-radius: 0px 0px 0 0;
          padding: 15px 15px;
        }
        @include mqh($md) {
          padding: 10px 10px;
        }
        .icon {
          @include mq($xls) {
            max-width: 30px;
            max-height: 30px;
          }
          @include mqh($sm) {
            max-width: 30px;
            max-height: 30px;
          }
        }
        .hapeBodyTopTitle {
          font-family: $sf-pro-medium;
          font-weight: 500;
          font-size: 17px;
          text-align: left;
          color: $white;
          @include mq($lg) {
            font-size: 16px;
          }
          @include mq($sm) {
            font-size: 14px;
          }
        }
      }
      .hape-body-bottom {
        background-color: $white;
        flex-grow: 1;
        padding: 18px 20px;
        display: flex;
        border-radius: 0px;
        border-radius: 0 0 0px 0px;
        max-height: 90%;
        @include mq($lg) {
          border-radius: 0 0 0px 0px;
          padding: 15px 15px;
        }
        @include mqh($md) {
          padding: 10px 10px;
        }
      }
    }
  }
}
