@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.publicProfile {
  :global {
    background-image: url("../../../public/images/bg-Pag.svg");
    border: none;
    @include page-setting;
    z-index: 2;
    padding: 15px;
    margin: 0;
    min-height: 100vh;
    max-height: fit-content;
    @include background-img;
    @include mq($xlg) {
      padding: 30px;
    }
    @include mq($lg) {
      padding: 2%;
    }
    @include mq($md) {
      padding: 15px;
    }
    @include mq($xls) {
      padding: 10px;
    }

    .pageBody {
      @include bodyChilds;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
      max-height: fit-content;
      padding-bottom: 2rem;
      @include mq($xlg) {
        gap: 10px;
      }
      @include mq($md) {
        background: transparent;
        backdrop-filter: blur(30px);
        --webkit-backdrop-filter: blur(30px);
      }
      @include mq($xms) {
        flex-direction: column;
      }
      .single_item_data {
        width: calc(100% / 5 - 20px);
        border-radius: 0px;
        padding: 0;
        aspect-ratio: 1/1;
        height: auto;
        @include mq($xlg) {
          width: calc(100% / 4 - 10px);
        }
        @include mq($lg) {
          width: calc(100% / 4 - 10px);
        }
        @include mq($md) {
          width: calc(100% / 3 - 10px);
        }
        @include mq($xls) {
          width: calc(100% / 2 - 10px);
        }
        @include mq($xms) {
          width: 100%;
        }
      }
      .profile {
        padding: 0;
        background-image: url("../../../public/images/userCrad.png") !important;
        @include background-img;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        width: calc(267px + 20px);
        max-width: fit-content;
        flex-grow: 0;
        height: fit-content;
        @include mq($xms) {
          width: 257px;
          max-width: unset;
        }

        .profilePhoto {
          width: 267px;
          aspect-ratio: 1/1;
          height: auto;
          border-radius: 0%;
          @include background-img;
          @include mq($xms) {
            width: 237px;
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          .top {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            height: fit-content;
            .name {
              font-family: $sf-pro-medium;
              color: $background;
              font-size: 20px;
              @include mq($xlg) {
                font-size: pxToRem(18);
              }
              @include mq($lg) {
                font-size: pxToRem(16);
              }
              @include mq($md) {
                font-size: pxToRem(15);
              }
              @include mq($xms) {
                font-size: pxToRem(14);
              }
            }
            .location {
              color: $background;
              font-family: $sf-pro-medium;
              font-size: 12px;
              opacity: 0.4;
              @include mq($xlg) {
                font-size: pxToRem(11);
              }
              @include mq($lg) {
                font-size: pxToRem(9);
              }
            }
          }

          .bio {
            font-size: 12px;
            display: flex;
            color: $background;
            justify-content: center;
            align-items: center;
            @include mq($xlg) {
              font-size: pxToRem(11);
            }
            @include mq($lg) {
              font-size: pxToRem(9);
            }
          }
        }
      }
    }
  }
}
