@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

#listOfProducts {
  :global {
    position: absolute;
    inset: 0;
    background: #2E2E2E url("../../../public/images/bg-Pag.svg") no-repeat center center / cover !important;
    border: none;
    z-index: 2;
    padding: 15px !important;
    margin: 0;
    @include background-img;
    @include page-setting;

    @include mq($xlg) {
      padding: 30px;
    }

    @include mq($lg) {
      padding: 2%;
    }

    @include mq($md) {
      padding: 15px;
    }

    @include mq($xls) {
      padding: 10px;
    }

    .pageBody {
      @include pageBody;

      .leftPagePart,
      .rightPagePart {
        @include bodyChilds;
      }

      .rightPagePart {
        min-height: calc(100vh - 170px);
        max-height: calc(100vh - 170px);
        overflow-y: scroll;
        @include scroll-bar;
      }
    }
  }
}