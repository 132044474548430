@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
#productDescription {
  :global {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5%;
    flex: 1 1 90%;
    max-height: 100%;
    .item {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .bottom-title {
        font-family: $sf-pro-medium;
        font-weight: 500;
        font-size: 30px;
        text-align: left;
        color: $black;
        @include mq($xlg) {
          font-size: 20px;
          font-weight: 700;
        }
        @include mq($md) {
          font-size: 18px;
        }
      }
      .bottom-body {
        font-family: $sf-pro-regular;
        font-size: 16px;
        text-align: left;
        color: $black;
        @include mq($xlg) {
          font-size: 14px;
        }
        @include mq($lg) {
          font-size: 12px;
        }
      }
    }
    .features {
      .bottom-body {
        max-width: 45%;
        @include mq($xlg) {
          max-width: 50%;
        }
        @include mq($lg) {
          max-width: 60%;
        }
      }
    }
    .specifications {
      .bottom-body {
        max-width: 25%;
        @include mq($xlg) {
          max-width: 45%;
        }
        @include mq($lg) {
          max-width: 50%;
        }
        @include mq($md) {
          max-width: 100%;
        }
      }
    }
  }
}
