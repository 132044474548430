@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.SingleMerchizeItem {
    $height: 35vh;
    min-height: $height;
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    position: relative;
    cursor: pointer;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    position: relative;

    :global {
        .behind {
            max-width: calc(100% - 24px);
            width: calc(100% - 24px);
            min-width: calc(100% - 24px);
            position: absolute;
            top:0;
            left: 0;
            padding: 12px;
        }

        .single-slide {
            min-height: $height;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #f5f5f5;
        }

        .progress-element {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 100;
            background: rgba($color: $black, $alpha: .5);
            top: 0;
            right: 0;
            transition: all 0.3s ease-out;
            backdrop-filter: grayscale(100%);
        }

        .top-layer{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: calc(100% - 64px);
            height: calc($height - 32px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 32px;
            padding-top: 32px;
            pointer-events: none;
        }

        .numbering {
            font-family: $sf-pro-heavy;
            font-style: normal;
            font-weight: 900;
            font-size: pxToEm(50);
            line-height: 1;
            color: #D9D9D9;
        }

        .product-name {
            font-size: pxToEm(20);
            color: $black;
            margin-bottom: 4px;
            font-family: $sf-pro-medium;
            font-style: normal;
            font-weight: 400;
        }

        .product-price {
            font-size: pxToEm(20);
            font-weight: 700;
            font-family: $sf-pro-bold;
            font-style: normal;
            color: lighten($color: $blue-v2, $amount: 10);
            margin-bottom: 8px;
        }

        .buy-now-btn {
            pointer-events: auto;
            background: radial-gradient(52.6% 150.7% at 50% 140.79%, $blue-v2 25%, rgba(116, 248, 79, 0.04) 100%), rgba(73, 54, 162, 0.16);
            background-blend-mode: overlay;
            border: 1px solid rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(15px);
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            height: 50px;
            display: grid;
            place-items: center;
            color: $white;
            transition: all 0.6s ease-out;
            cursor: pointer;

            &:hover {
                background: radial-gradient(52.6% 150.7% at 50% 140.79%, lighten($blue-v2, 6) 8%, rgba(116, 248, 79, 0.04) 100%);
            }
        }
    }
}