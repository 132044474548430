@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

#LHeader {
    background-color: red;
    padding: 35.5px 0px 35.5px 53.8px;
    max-width: 100vw;
    width: 100%;
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, 0.31);
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;

    :global {
        .l-logo {
            img {
                width: auto;
                height: 42.74px;
                background: transparent;

                @include mq($xlg) {
                    height: calc(42.74px / 2);
                }
            }

        }

        .links {
            display: flex;
            gap: pxToEm(110);
            align-items: center;
            padding-right: 150px;

            @include mq($xlg) {
                gap: pxToEm(110/3);
            }

            a {
                text-decoration: none;
                font-family: $urbanist;
                font-weight: 100;
                font-size: pxToEm(36);
                text-align: left;
                color: $white;
                text-transform: uppercase;

                @include hoverGreen;

                @include mq($xlg) {
                    font-size: 16px;
                }
            }
        }
    }
}