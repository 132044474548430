@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

.autoGenerated {
  :global {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0px;

    @mixin cardMixin {
      width: calc((100%/3) - 12px);
      height: auto;
      aspect-ratio: 1/1;
      @include mq($xlg) {
          width: calc((100%/2) - 6px);
        }
      @include mq($lg) {
        width: 100%
      }
    }

    .empty-card {
      @include cardMixin;
      @include background-img;
    }

    .loading-card {
      @include cardMixin;
      display: grid;
      place-items: center;
      @include dashboardPagesBackground;
      border-radius: 0px;
      position: relative;
      border: solid 2px $blue;
    }

    .error-card {
      @extend .loading-card;
      border-color: $red;

      img {
        width: 154px;
        height: auto;
      }

      .erro-text-container {
        display: grid;
        place-items: center;
        gap: 12px;
      }

      .error {
        width: 90%;
        font-family: $sf-pro-medium;
        font-size: pxToEm(14);
        line-height: 1.3;
        text-align: center;
        color: $black;
        opacity: 40%;
      }
    }

    @include mq($xlg) {
      padding: 10px;
    }

    @include mq($lg) {
      padding: 8px;
    }

    @include mq($md) {
      padding: 6px;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      z-index: 0;

      @include mqh($xms) {
        padding: 5px;
      }

      .header {
        width: 100%;
      }

      .details {
        width: calc(100% - 40px);
        border-radius: 0px;
        padding: 16px;
        border: none;
        box-shadow: none;
        font-family: $sf-pro-medium;

        @include mq($xlg) {
          padding: 10px;
          width: calc(100% - 30px);
        }

        @include mq($lg) {
          padding: 8px;
          width: calc(100% - 15px);
        }

        @include mq($md) {
          padding: 10px;
          border-radius: 0px;
          width: calc(100% - 10px);
        }

        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }

        &::placeholder {
          font-family: $sf-pro-medium;
        }
      }

      .action {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .choose_style {
      width: 100%;
      display: grid;
      gap: 8px;
    }

    .container_images {
      width: 100%;
      padding: 0;
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: transparent;
      margin: 0;

      .images_box {
        width: 100%;
        display: flex;
        grid-template-columns: 1fr 1fr 1fr;
        flex-wrap: wrap;
        gap: 16px;
        padding: 0;
        margin: 0;

        @include mq($xlg) {
          gap: 10px;
        }

        @include mq($md) {
          gap: 8px;
        }

        @include mq($lg) {
          grid-template-columns: 1fr 1fr;
        }

        @include mq($xlsh) {
          grid-template-columns: 1fr;
        }

        .card {
          height: auto;
          @include cardMixin;
          padding: 0;
          border-radius: 0px;
          position: relative;

          @include mq($lg) {
            border-radius: 0px;
          }

          @include mq($xls) {
            border-radius: 0px;
          }

          @include mq($xlsh) {
            border-radius: 0px;
          }

          .btns_container {
            height: 100%;
            background-color: transparent;
            border-radius: inherit;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 0;
            align-items: flex-start;
            max-height: 100%;
            max-width: 100%;
            padding: 12px;
            @include background-img;

            @include mq($xlg) {
              padding: 13px;
            }

            @include mq($lg) {
              padding: 10px;
            }

            @include mq($md) {
              padding: 8px;
            }

            .actions_top {
              width: fit-content;
            }

            .clickableSeciton {
              flex-grow: 1;
              cursor: pointer;
              height: 70%;
              width: calc(100% - 16px);
            }

            .btn {
              display: flex;
              gap: 20px;
              padding: 5px;
              padding-left: 20px;
              height: 40px;
              border-radius: 0px;
              font-family: $sf-pro-medium;
              color: $white;
              font-size: 13px;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              background: transparent;
              backdrop-filter: blur(15px);
              --webkit-backdrop-filter: blur(15px);
              background-color: rgba(255, 255, 255, 0.15);

              @include mq($xlg) {
                gap: 15px;
                padding-left: 12px;
                font-size: 11px;
                height: 30px;
              }

              @include mq($lg) {
                border-radius: 0px;
                gap: 10px;
                height: 30px;
                padding-left: 10px;
                font-size: 10px;
              }

              @include mq($md) {
                height: 35px;
                gap: 15px;
                padding-left: 15px;
                font-size: 15px;
                border-radius: 0px;
              }

              .btnLogo {
                height: 100%;
                border-radius: 0%;
                aspect-ratio: 1/1;
                width: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: $background;
                background-color: $opacityblack;

                .editIcon {
                  height: 12px;
                  width: 12px;

                  @include mq($lg) {
                    height: 10px;
                  }
                }
              }
            }

            .actions_bottom {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-between;
              position: relative;

              .number {
                font-family: $sf-pro-bold;
                font-weight: 500;
                font-size: pxToEm(49);
                text-align: left;
                color: $white;

                @include mq($xlg) {
                  font-size: pxToEm(40);
                }

                @include mq($lg) {
                  font-size: pxToEm(35);
                }

                @include mq($md) {
                  font-size: pxToEm(30);
                }
              }
            }
          }
        }
      }
    }

    textarea {
      border: solid 2px black !important;
    }
  }
}