@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

#CreateSection {
    background-color: #2E2E2E;

    :global {
        .lp {
            @include landingPadding;
        }

        .inBtwnImage {
            min-height: pxToVh(613);
            background-attachment: fixed;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .flex {
            display: flex;
            justify-content: space-between;
            font-family: $urbanist;
            font-weight: 100;
            font-size: pxToEm(40);
            text-align: left;
            padding-top: pxToVw(83);
            padding-bottom: 65px;
            color: #868686;

            @include mq($xlg) {
                font-size: 24px;
                padding-bottom: 25px;
            padding-top: pxToVw(40);
            }

            .si-text {
                &:first-child {
                    color: #56bcc7;
                }
            }

        }

        .desc-text {
            font-family: $urbanist;
            font-weight: 300;
            font-size: 36px;
            text-align: left;
            color: #a9a9a9;
            padding-top: 0px;
            padding-bottom: pxToVw(151);
            max-width: (1047/1920)*100%;

            @include mq($xlg) {
                font-size: 16px;
            }
        }

        .product-pictures {
            padding: 66px 0px;
            background: $white;
            display: flex;
            gap: pxToEm(80);
            width: ((297*2)/1920*100)+100%;
            transform: translateX(-12.5%);
        }

        .single-image {
            width: 785px;
            height: 440px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}