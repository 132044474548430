@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.emptyGlb {
  :global {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .bg_empty {
      width: 50%;
      height: auto;
      margin-bottom: 20px;
    }

    .details {
      margin: 0px auto;
      max-width: 50%;
    }

    .btn {
      margin: 0 auto;
    }

  }
}