@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.dashboardLayout {
  :global {
    position: absolute;
    inset: 0;
    margin: 0;
    z-index: 2;
    overflow-x: hidden;
    background-color: $background;
    display: flex;
    gap: 20px;
    min-height: 100vh;
    max-height: fit-content;
    overflow: hidden;
    overflow: hidden;

    @include mq($xlg) {
      padding: 30px;
      gap: 15px;
    }

    @include mq($lg) {
      padding: 2%;
    }

    @include mq($md) {
      padding: 15px;
      gap: 10px;
    }

    @include mq($xls) {
      padding: 10px;
    }

    .center {
      width: 100%;
      display: grid;
      place-items: center;
    }

    .body_glb {
      background-color: $black;
      flex-grow: 1;
      height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;
      gap: 29px;
      border-radius: 12px;
      margin-left: 279px;

      @include mq($xlg) {
        gap: 15px;
      }

      @include mq($md) {
        gap: 10px;
      }

      .upload_preview {
        padding: 20px;
        flex-grow: 1;
        background-color: $black;
        border-radius: 0px;
        overflow: hidden;
        min-height: calc(100vh - 145px);
        @include scroll-bar;

        @include mq($xlg) {
          gap: 15px;
          padding: 15px;
        }

        @include mq($md) {
          gap: 10px;
          padding: 10px;
        }
      }
    }

  }
}