@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

#footer {
    @include landingPadding;

    :global {
        background: #2E2E2E;
        padding-top: 42px;

        .flex {
            display: flex;
            justify-content: center;
        }

        .footer-btn {
            border-radius: 40px;
            background: transparent;
            border: 5px solid #69db49;
            opacity: 0.77;
            padding: 16px 113px 16px 113px;
            font-family: $urbanist;
            font-weight: 500;
            font-size: pxToEm(40);
            text-align: left;
            color: #74f652;

            @include mq($xlg) {
                font-size: 16px;
                border: 2px solid #69db49;
            }
        }


        .links {
            display: flex;
            flex-direction: column;
            gap: 29px;

            @include mq($xlg) {
                gap: 15px;
            }

            .link {
                font-family: $urbanist;
                font-weight: 500;
                font-size: 18px;
                letter-spacing: 0.1em;
                line-height: 20px;
                text-align: left;
                color: #707070;
                @include hoverGreen;

                @include mq($xlg) {
                    font-size: 14px;
                }
            }
        }

        .social {
            display: flex;
            gap: 45px;

            img {
                width: 20px;
                height: auto;

                @include mq($xlg) {
                    height: 14px;
                }
            }
        }

        .footer-socials {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 48px;
            padding-bottom: 48px;
        }
    }
}