@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.previewPopup {
  :global {

    .titlePreview {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn_light {
      background-color: $background;
      color: $black;
    }

    .image_preview {
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}