@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#page404 {
  :global {
    position: absolute;
    inset: 0;
    padding: 20px;
    margin: 0;
    z-index: 2;
    overflow-x: hidden;
    background-color: $background;
    background-image: url("../../../../../public/images/bgsubmission.svg");
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: pxToVh(122);
    @include background-img;

    @include mq($xlg) {
      padding: 15px;
    }

    @include mq($lg) {}

    @include mq($md) {}

    @include mq($xls) {
      padding: 10px;
    }

    .body {
      max-height: pxToVh(676);
      border-radius: 15px;
      border: 1px solid $gray;
      background-color: $background;
      padding: 20px 32px;
      max-width: pxToVw(706);
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include mq($xlg) {
        padding: 15px 25px;
      }

      @include mq($lg) {
        padding: 15px 20px;
      }

      @include mq($md) {
        padding: 15px 15px;
      }

      @include mqh($xls) {
        padding: 10px 15px;
      }

      .centered {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-weight: 500;
        font-size: pxToEm(17.72);
        line-height: 1.3;
        text-align: center;

        * {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .title {
        font-weight: normal;
        font-size: pxToEm(22);
      }

      .imgTop {
        width: pxToVw(641);
        height: pxToVh(641);
        background-image: url("../../../../../public/images/404.svg");
        @include background-img;
      }
    }
  }
}